"use client";

import { Link } from "@/components/Link/Link.component";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { imageLoader } from "@/utilities/image-loader";
import NextImage from "next/image";
type ArticleTeaserImageProps = {
  readonly targetUrl: string;
  readonly src: string;
  readonly alternativeText: string;
  readonly sourceSetSize?: string;
  readonly hasImagePriority?: boolean;
};
export function ArticleTeaserImage({
  alternativeText,
  hasImagePriority,
  sourceSetSize,
  src,
  targetUrl
}: ArticleTeaserImageProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  return <Link className="relative block h-full w-full" href={targetUrl} position="img" data-sentry-element="Link" data-sentry-component="ArticleTeaserImage" data-sentry-source-file="ArticleTeaserImage.component.tsx">
      <NextImage alt={alternativeText} className="bg-whisper object-cover" fill loader={imageLoader(serverUrl)} priority={hasImagePriority} sizes={sourceSetSize} src={src} data-sentry-element="NextImage" data-sentry-source-file="ArticleTeaserImage.component.tsx" />
    </Link>;
}